<template>
  <div class="validate-quote__wrapper">
    <div class="validate-quote__subtitle">
      {{ $t('validate_quote.subtitle') }}
    </div>
    <SalesContact :owner="owner" />
    <QuoteDetails />
    <DownsellDetails v-if="displayDownsell" />
    <BillingDetails :billing-info="billingInfo" />
    <div class="validate-quote__billing-subtitle">
      {{ $t('validate_quote.billing_subtitle') }}
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="validate-quote__quote-validation"
      v-html="
        $t('validate_quote.quote_validation', { link: $t('validate_quote.quote_validation_link') })
      "
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  mapGetters,
  mapState,
} from 'vuex';
import SalesContact from '@components/SalesContact';
import QuoteDetails from '@components/QuoteDetails';
import BillingDetails from '@components/BillingDetails';
import DownsellDetails from '@components/DownsellDetails';

export default {
  name: 'ValidateQuote',
  components: { SalesContact, BillingDetails, QuoteDetails, DownsellDetails },
  computed: {
    ...mapState(['quote']),
    ...mapGetters(['isContractChange']),
    displayDownsell() {
      return this.isContractChange && this.quote.quote_line_items.some(line => line.status === 'Deleted');
    },
    owner() {
      const userData = this.quote.skello_sales_owner;
      const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);
      return {
        name: `${capitalize(userData.first_name)} ${userData.last_name[0].toUpperCase()}.`,
        email: userData.email,
        phone: userData.phone,
      };
    },
    billingInfo() {
      const toDateString = date => dayjs(new Date(date)).format('DD/MM/YYYY');
      const address = this.quote.organisation.billing_address;
      const skello = this.quote.skello_billing_details;
      return {
        quotationCreationDate: toDateString(this.quote.created_at),
        quotationExpirationDate: toDateString(this.quote.valid_till),
        startingDate: this.quote.contract_terms.contract_term_start,
        from: {
          company: skello.company,
          line1: skello.line1,
          line2: skello.line2,
          zip: skello.zip,
          city: skello.city,
          stateCode: skello.state_code,
          state: skello.state,
          country: skello.country,
        },
        to: {
          company: address.company,
          line1: address.line1,
          line2: address.line2,
          zip: address.zip,
          city: address.city,
          stateCode: address.state_code,
          state: address.state,
          country: address.country,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.validate-quote__subtitle {
  margin-top: 13px;
  margin-bottom: 16px;
  font-size: 16px;
}

.validate-quote__billing-subtitle {
  font-size: $fs-text-m;
  margin-bottom: 12px;
}

.validate-quote__quote-validation {
  margin-bottom: 48px;
}
</style>
