import Vue from 'vue';
import SkelloUi from '@skelloapp/skello-ui';
import i18n from '@/plugins/i18n';
import App from './App.vue';
import store from './store';
import router from './app-routes';

import '@skelloapp/skello-ui/dist/skello-ui.css';

Vue.use(SkelloUi);

Vue.mixin({
  methods: {
    makeToast(type, message) {
      const variant = type === 'is-danger' ? 'error' : 'success';
      this.$skToast({
        variant,
        message,
      });
    },
    makeAlertToast(message) {
      this.makeToast('is-danger', message);
    },
    makeSuccessToast(message) {
      this.makeToast('is-success', message);
    },
  },
});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app');
