const {
  VUE_APP_AWS_ENV,
  VUE_APP_CHARGEBEE_SITE,
  VUE_APP_DISPLAY_NEW_BILLING_LINE,
  VUE_APP_ONBOARDING_BASE_URL,
  VUE_APP_SKELLO_API_URL,
  VUE_APP_SVC_BILLING_AUTOMATION_URL,
  VUE_APP_WEBSOCKET_URL,
} = import.meta.env;

export {
  VUE_APP_AWS_ENV,
  VUE_APP_CHARGEBEE_SITE,
  VUE_APP_DISPLAY_NEW_BILLING_LINE,
  VUE_APP_SKELLO_API_URL,
  VUE_APP_SVC_BILLING_AUTOMATION_URL,
  VUE_APP_ONBOARDING_BASE_URL,
  VUE_APP_WEBSOCKET_URL,
};
