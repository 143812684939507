<template>
  <div class="downsell-details__wrapper">
    <div class="downsell-details__header">
      {{ $t('downsell_details.title') }}
    </div>
    <div class="downsell-details__content">
      <div
        v-for="line, index in downsellLines"
        :key="index"
      >
        <DownsellCollapseLine
          :line="line"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DownsellCollapseLine from '@components/DownsellCollapseLine';
import { extractShopsNames } from '@/shared/utils/method_helper';

export default {
  name: 'DownsellDetails',
  components: {
    DownsellCollapseLine,
  },
  computed: {
    ...mapState(['quote']),
    downsellLines() {
      const consolidatedLineItems = {};

      this.quote.quote_line_items.forEach(lineItem => {
        const existingLineItem = consolidatedLineItems[`${lineItem.title}--${lineItem.status}`];

        if (existingLineItem && lineItem.status === 'Deleted') {
          existingLineItem.quantity += lineItem.quantity;
          existingLineItem.quote_line_item_shops.push(...lineItem.quote_line_item_shops);
        } else if (lineItem.status === 'Deleted') {
          consolidatedLineItems[`${lineItem.title}--${lineItem.status}`] = lineItem;
        }
      });

      return Object.values(consolidatedLineItems).map(lineItem => ({
        name: lineItem.title,
        quantity: lineItem.quantity,
        shopsNames: extractShopsNames(lineItem, this.quote),
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.downsell-details__wrapper {
  background-color: $sk-grey-5;
  border-radius: 8px;
  margin-bottom: 16px;
}

.downsell-details__header {
  border-bottom: 1px solid $sk-grey-10;
  padding: 19px 24px;
  font-size: $fs-text-l;
  font-weight: $fw-bold;
}

.downsell-details__content {
  padding: 24px;
}

</style>
