import { VUE_APP_WEBSOCKET_URL } from '@/shared/config';

export default class WebsocketClient {
  static CLOSE_NORMAL = 1000;

  static MAX_RETRIES = 10;

  constructor(websocketConfig) {
    this.url = `${VUE_APP_WEBSOCKET_URL}/?type=${websocketConfig.type}&uuid=${websocketConfig.uuid}`;
    this.client = null;
    this.retries = 0;
    this.isClosed = false;
  }

  connect(callback) {
    this.client = new WebSocket(this.url);
    this.client.onmessage = event => {
      // eslint-disable-next-line no-console
      console.log({ type: 'Message Received', event });
      callback(event);
    };
    this.client.onclose = event => {
      // eslint-disable-next-line no-console
      console.log({ type: 'Close received', event });
      // 1000 is the normal case, everything else is not normal so we'll try to reconnect
      // cf. https://developer.mozilla.org/fr/docs/Web/API/CloseEvent
      if (
        event.code !== WebsocketClient.CLOSE_NORMAL && this.retries < WebsocketClient.MAX_RETRIES
      ) {
        this.retries += 1;
        setTimeout(this.connect(callback), 2 ** this.retries * 100);
      }
    };
    this.client.onopen = () => {
      // eslint-disable-next-line no-console
      console.log({ type: 'Open received' });
      // Reset retries on successful connection opened
      this.retries = 0;
      // Fallback if disconnect was called before the connection was opened
      if (this.isClosed) {
        this.client.close(WebsocketClient.CLOSE_NORMAL);
      }
    };
  }

  disconnect() {
    // eslint-disable-next-line no-console
    console.log({ type: 'Disconnect received' });
    this.isClosed = true;
    this.client.close(WebsocketClient.CLOSE_NORMAL);
  }
}
