<template>
  <div>
    <div class="toggle-line">
      <div class="collapse-line__item--name">
        <div
          class="collapse-line__item--caret"
          @click="handleCaretClick"
        >
          <component
            :is="caretIcon"
            class="caret-icon"
            fill="#727272"
            height="16"
            width="16"
          />
        </div>
        <slot name="top-left-text" />
      </div>
      <slot name="top-right-text" />
    </div>
    <div
      v-if="isOpen"
      class="collapse-line__open--wrapper"
    >
      <div
        v-for="name, index in line.shopsNames"
        :key="index"
        class="collapse-line__open--text"
      >
        <div class="toggle-line">
          <div>
            {{ name }}
          </div>
          <slot name="bottom-right-text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CaretDownV2Icon,
  CaretUpV2Icon,
} from '@skelloapp/skello-ui';

export default {
  name: 'ToggleLine',
  components: {
    CaretDownV2Icon,
    CaretUpV2Icon,
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    caretIcon() {
      return this.isOpen ? 'CaretUpV2Icon' : 'CaretDownV2Icon';
    },
  },
  methods: {
    handleCaretClick() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 16px;
}

.collapse-line__item--name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.collapse-line__item--caret {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.caret-icon {
  padding-right: 4px;
}

.collapse-line__open--wrapper {
  margin: 8px 0 0 6px;
  line-height: 13px;
  border-left: 1px solid $sk-grey-10;
}

.collapse-line__open--text {
  font-size: $fs-text-s;
  color: $sk-grey-50;
  padding: 4px 0px 4px 8px;
  &:not(:first-child) {
    margin-top: 8px;
  }
}
</style>
