<template>
  <div class="upsell-banner__wrapper">
    <CrownIcon :fill="$skColors.skGold50" />
    <div
      class="upsell-banner__description sk-text-medium-regular"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="description" />
    </div>
  </div>
</template>

<script>
import { CrownIcon } from '@skelloapp/skello-ui';

export default {
  name: 'UpsellBanner',
  components: {
    CrownIcon,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell-banner {
  &__wrapper {
    display: flex;
    width: 100%;
    padding: 16px;
    gap: 8px;
    border-radius: 8px;
    background-color: $sk-gold-5;
  }

  &__description {
    display: flex;
    flex: 1;
    width: 100%;
    align-self: center;
    padding: 4px 0;
  }
}
</style>

<style lang="scss">
.upsell-banner__description b {
  font-weight: $fw-semi-bold;
}
</style>
