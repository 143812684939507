<template>
  <div class="sales-contact">
    <div class="sales-contact__icon">
      <InfoV2Icon
        fill="#2B66FE"
        width="20"
        height="20"
      />
    </div>
    <div>
      <span class="text--grey">
        {{ $t('validate_quote.contact.name') }}
        <b class="sales-contact__data text--black">
          {{ owner.name }}
        </b>
      </span>
      <br>
      <span
        v-if="owner.phone"
        class="sales-contact__phone text--grey"
      >
        {{ $t('validate_quote.contact.phone', {phone: owner.phone}) }}
      </span>
      <span
        v-if="owner.email"
        class="text--grey"
      >
        {{ $t('validate_quote.contact.email', {email: owner.email}) }}
      </span>
    </div>
  </div>
</template>

<script>
import { InfoV2Icon } from '@skelloapp/skello-ui';

export default {
  name: 'SalesContact',
  components: { InfoV2Icon },
  props: {
    owner: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-contact {
  display: flex;
  margin-bottom: 24px;
}

.text--grey {
  color: $sk-grey-50;
}

.text--black {
  color: $sk-black;
}

.sales-contact__phone {
  margin-right: 25px;
}

.sales-contact__icon {
  margin: 2px 8px 0 0;
}
</style>
