<template>
  <div class="onboarding__page-container">
    <div class="onboarding__left-container">
      <img
        :src="currentStep.sidebarImg"
        class="onboarding__sidebar-img"
      >
    </div>
    <div
      class="onboarding__right-container"
    >
      <div class="right-container__main">
        <div class="onboarding__header-container">
          <div class="onboarding__header__logo">
            <img
              class="onboarding__logo"
              src="@assets/logo-skello.svg"
            >
          </div>
          <div
            v-if="!currentStep.stepCountHidden"
            class="onboarding__header__step-count"
          >
            {{ $t(
              'step_count', {currentStep: currentStep.stepIndex, totalStep: totalStepCount}
            ) }}
          </div>
        </div>
        <div class="onboarding__main-container">
          <div class="main-container__title">
            {{ currentStep.title }}
          </div>
          <slot />
        </div>
      </div>
      <div
        v-if="!currentStep.submitHidden"
        class="right-container__footer"
      >
        <SkButton
          class="footer__submit"
          data-test="onboarding-submit-step"
          :disabled="stepLoading || isSubmitDisabled"
          :spinner="stepLoading"
          @click="$emit('submit')"
        >
          {{ currentStep.submitLabel }}
        </SkButton>
      </div>
    </div>
  </div>
</template>

<script>
import { SkButton } from '@skelloapp/skello-ui';

export default {
  name: 'MultiStepForm',
  components: { SkButton },
  props: {
    currentStep: {
      type: Object,
      required: true,
    },
    totalStepCount: {
      type: Number,
      required: true,
    },
    stepLoading: {
      type: Boolean,
      default: false,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding__page-container {
  display: flex;
  height: 100vh;
}

.onboarding__sidebar-img {
  height: 100vh;
}

.onboarding__left-container {
  background-color: $sk-blue-5;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1343px), (max-height: 650px) {
    display: none;
  }
}

.onboarding__right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: 100px;
  overflow-x: hidden;
}

.right-container__main {
  padding: 65px 48px 0px 48px;

  @media (min-width: $md-width) and (max-width: 1343px) {
    padding: 65px 44px 0px 40px;
  }

  @media (max-width: $md-width) {
    padding: 65px 20px 0px 40px;
  }

  @media (max-width: $sm-width) {
    padding: 65px 20px 0;
  }
}

.onboarding__header-container {
  display: flex;
  justify-content: space-between;
  place-items: center;
  margin-bottom: 42px;
}

.onboarding__header__logo {
  border-radius: 127px;
  box-shadow: 14px 14px 60px rgb(0 0 0 / 12%);
  padding: 12px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding__logo {
  width: 70px;
}

.onboarding__header__step-count {
  font-size: 16px;
  color: $sk-grey-50;
}

.main-container__title {
  font-size: 24px;
  line-height: 36px;
  color: $sk-black;
  font-weight: $fw-bold;
}

.right-container__footer {
  display: flex;
  align-items: center;
  justify-content: end;
  min-height: 90px;
  height: 90px;
  border-top: 1px solid $sk-grey-10;
  position: sticky;
  bottom: 0;
  background: white;
}

.footer__back {
  color: $sk-grey-50;
  margin-left: 32px;
  cursor: pointer;
}

.footer__submit {
  margin-right: 100px;

  @media (min-width: $md-width) and (max-width: 1343px) {
    margin-right: 44px;
  }

  @media (max-width: $md-width) {
    margin-right: 20px;
  }

  @media (max-width: $sm-width) {
    margin-right: 20px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
