import Vue from 'vue';
import Router from 'vue-router';

import ContractChange from '@views/ContractChange';
import CreatePassword from '@views/CreatePassword';
import CreateShop from '@views/CreateShop';
import CreateShopLoader from '@views/CreateShopLoader';
import FillBillingInfo from '@views/FillBillingInfo';
import Onboarding from '@views/Onboarding';
import ProvisioningLoader from '@views/ProvisioningLoader';
import StepError from '@views/StepError';
import ValidateQuote from '@views/ValidateQuote';

Vue.use(Router);

const routes = [
  {
    path: '/',
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: Onboarding,
    children: [
      {
        path: 'validate_quote',
        name: 'validateQuote',
        component: ValidateQuote,
      },
      {
        path: 'fill_billing_info',
        name: 'fillBillingInfo',
        component: FillBillingInfo,
      },
      {
        path: 'create_password',
        name: 'createPassword',
        component: CreatePassword,
      },
      {
        path: 'provisioning_loading',
        name: 'provisioningLoading',
        component: ProvisioningLoader,
        props: true,
      },
      {
        path: 'error',
        name: 'error',
        component: StepError,
        props: true,
      },
    ],
  },
  {
    path: '/contract_change',
    name: 'contractChange',
    component: ContractChange,
    children: [
      {
        path: 'create_shop',
        name: 'createShop',
        component: CreateShop,
      },
      {
        path: 'error',
        name: 'contractChangeError',
        component: StepError,
        props: true,
      },
      {
        path: 'create_shop_loader',
        name: 'createShopLoader',
        component: CreateShopLoader,
        props: true,
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  routes,
});

export default router;
