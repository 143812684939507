import axios from 'axios';
import { VUE_APP_AWS_ENV } from '@/shared/config';

const longFormAwsEnv = {
  rapp: 'reviewapps',
  sdbx: 'sandbox',
  stag: 'staging',
  prod: 'production',
}[VUE_APP_AWS_ENV];

const BASE_URL = VUE_APP_AWS_ENV === 'prod' ? 'https://svc-feature-flags.skello.io' : `https://svc-feature-flags.${longFormAwsEnv}.skello.io`;

export default class FeatureFlagClient {
  constructor() {
    this.client = axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: 'application/json',
        'X-Source-Client': 'skelloOnboarding',
      },
      timeout: 5000,
    });
  }

  async fetchFeatures() {
    return this.client.get('/features');
  }
}
