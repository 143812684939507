<template>
  <div class="onboarding__page-container">
    <div class="onboarding__left-container">
      <img
        :src="currentStep.sidebarImg"
        class="onboarding__sidebar-img"
      >
    </div>
    <div
      class="onboarding__right-container"
    >
      <div class="right-container__main">
        <div class="onboarding__main-container">
          <div class="sk-display-small-semibold">
            {{ currentStep.title }}
          </div>
          <slot />
        </div>
      </div>
      <div
        v-if="!currentStep.submitHidden"
        class="right-container__footer"
      >
        <SkOroraButton
          class="footer__submit"
          data-test="onboarding-submit-step"
          :disabled="isSubmitDisabled"
          :loading="stepLoading"
          @click="$emit('submit')"
        >
          {{ currentStep.submitLabel }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContractChangeForm',
  props: {
    currentStep: {
      type: Object,
      required: true,
    },
    stepLoading: {
      type: Boolean,
      default: false,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding__page-container {
  display: flex;
  height: 100vh;
}

.onboarding__sidebar-img {
  height: 100vh;
}

.onboarding__left-container {
  background-color: $sk-blue-5;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1343px), (max-height: 650px) {
    display: none;
  }
}

.onboarding__right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: 100px;
  overflow-x: hidden;
}

.right-container__main {
  padding: 32px 48px 0px 48px;

  @media (min-width: $md-width) and (max-width: 1343px) {
    padding: 32px 44px 0px 40px;
  }

  @media (max-width: $md-width) {
    padding: 32px 20px 0px 40px;
  }

  @media (max-width: $sm-width) {
    padding: 32px 20px 0;
  }
}

.onboarding__header-container {
  display: flex;
  justify-content: space-between;
  place-items: center;
  margin-bottom: 42px;
}

.onboarding__header__logo {
  border-radius: 127px;
  box-shadow: 14px 14px 60px rgb(0 0 0 / 12%);
  padding: 12px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding__logo {
  width: 70px;
}

.onboarding__header__step-count {
  font-size: 16px;
  color: $sk-grey-50;
}

.right-container__footer {
  display: flex;
  align-items: center;
  justify-content: end;
  min-height: 90px;
  height: 90px;
  position: sticky;
  bottom: 0;
  background: white;
}

.footer__back {
  color: $sk-grey-50;
  margin-left: 32px;
  cursor: pointer;
}

.footer__submit {
  margin-right: 100px;

  @media (min-width: $md-width) and (max-width: 1343px) {
    margin-right: 44px;
  }

  @media (max-width: $md-width) {
    margin-right: 20px;
  }

  @media (max-width: $sm-width) {
    margin-right: 20px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
