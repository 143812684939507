<template>
  <div>
    <MultiStepForm
      :current-step="currentStep"
      :total-step-count="totalStepCount"
      :step-loading="stepLoading"
      :is-submit-disabled="isSubmitDisabled"
      @submit="submitStep"
    >
      <RouterView
        @disable-submit="handleDisableSubmit"
        @enable-submit="handleEnableSubmit"
      />
    </MultiStepForm>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import MultiStepForm from '@components/MultiStepForm';
import WebsocketClient from '@/shared/utils/clients/websocketClient';
import { VUE_APP_SKELLO_API_URL } from '@/shared/config';
import { billingAutomationClient } from '@/shared/utils/clients/billingAutomationClient';
import { skelloHttpClient } from '@/shared/utils/clients/skelloHttpClient';
import { PROVISIONING_FAILED } from '@/shared/constants/errors';
import {
  organisationIsBillable,
  isBillable,
  isCustomerPaymentInfoCompleted,
} from '@/shared/utils/method_helper';

export default {
  name: 'Onboarding',
  components: { MultiStepForm },
  data() {
    return {
      stepLoading: false,
      isSubmitDisabled: false,
      websocketClient: null,
    };
  },
  computed: {
    ...mapState(['quote', 'password']),
    ...mapGetters(['displayPaymentByCreditCard', 'isBillingOnOrganisation']),

    billingInfoCompletedForCreditCardPayment() {
      if (this.isBillingOnOrganisation) {
        return isCustomerPaymentInfoCompleted(this.quote.organisation);
      }

      return this.quote.organisation.shops.every(isCustomerPaymentInfoCompleted);
    },
    billingInfoCompleted() {
      if (!this.quote.organisation) return false;

      const organisation = this.quote.organisation;

      if (this.displayPaymentByCreditCard) {
        return this.billingInfoCompletedForCreditCardPayment;
      }

      const hasShopAwaitingPayment =
        organisation.shops
          .filter(isBillable)
          .find(shop => !shop.has_valid_payment_method);
      // If Organisation not billable, then we don't check if has_valid_payment_method
      const hasOrganisationAwaitingPayment =
        organisationIsBillable(this.quote.organisation) && !organisation.has_valid_payment_method;
      return !hasShopAwaitingPayment && !hasOrganisationAwaitingPayment;
    },
    isQuoteInvalid() {
      return !this.quote ||
        !this.quote.organisation ||
        this.isQuoteExpired ||
        !['Sent', 'Presented', 'Accepted'].includes(this.quote.status);
    },
    isQuoteExpired() {
      const expirationDate = new Date(this.quote.valid_till);
      if (!expirationDate) return false;

      // JS counts in milliseconds, other programs in seconds
      // In order to avoid float bugs we convert up, not down
      return new Date() > expirationDate;
    },
    currentStep() {
      if (this.$route.name === 'onboarding') return this.onboardingSteps.find(step => step.routerName === 'validateQuote');

      return this.onboardingSteps.find(step => step.routerName === this.$route.name);
    },
    totalStepCount() {
      // no need to count the error and loadingProvisioning step
      return this.onboardingSteps.length - 2;
    },
    // Needs to be a computed to reload on i18n lang change
    onboardingSteps() {
      return [
        {
          title: this.validateQuoteTitle,
          routerName: 'validateQuote',
          sidebarImg: this.sidebarImgLink('ValidateQuote'),
          stepIndex: 1,
          submitAction: this.validateQuote,
          submitLabel: this.validateQuoteSubmitLabel,
        },
        {
          title: this.$t('fill_billing_info.title'),
          routerName: 'fillBillingInfo',
          sidebarImg: this.sidebarImgLink('FillBillingInfo'),
          stepIndex: 2,
          submitAction: this.updateQuoteBillingInfos,
          submitLabel: this.$t('fill_billing_info.submit_label'),
        },
        {
          title: this.$t('create_password.title'),
          routerName: 'createPassword',
          sidebarImg: this.sidebarImgLink('CreatePassword'),
          stepIndex: 3,
          submitAction: this.submitProvisioning,
          submitLabel: this.$t('create_password.submit_label'),
        },
        {
          title: this.$t('provisioning_loader.title'),
          routerName: 'provisioningLoading',
          sidebarImg: this.sidebarImgLink('CreatePassword'),
          stepCountHidden: true,
          submitAction: async () => { },
        },
        {
          title: this.$t('step_error.title'),
          routerName: 'error',
          sidebarImg: this.sidebarImgLink('ValidateQuote'),
          stepCountHidden: true,
          submitAction: async () => { },
          submitHidden: true,
        },
      ];
    },
    validateQuoteTitle() {
      return this.$t('validate_quote.title');
    },
    validateQuoteSubmitLabel() {
      return this.$t('validate_quote.submit_label');
    },
    quoteId() {
      return this.$route.query.id;
    },
  },
  created() {
    if (this.getCurrentStep() === 'provisioningLoading') {
      this.stepLoading = true;
    }

    if (this.$route.name !== this.getCurrentStep() && (this.$route.name !== 'error' || this.$route.name !== 'contractChangeError')) {
      this.$router.push({ name: this.getCurrentStep(), query: this.$route.query });
    }
  },
  destroyed() {
    if (this.websocketClient) {
      this.websocketClient.disconnect();
    }
  },
  methods: {
    ...mapActions([
      'validateQuote',
      'updateQuoteBillingInfos',
    ]),
    ...mapMutations(['setProvisioningAction']),
    redirectToErrorPage(errorType) {
      this.$router.push({ name: 'error', query: this.$route.query, params: { error: errorType } });
    },
    sidebarImgLink(step) {
      return this.sidebarAssetsPath()[step];
    },
    sidebarAssetsPath() {
      return {
        ValidateQuote: new URL('@assets/sidebar-images/ValidateQuote.svg', import.meta.url).href,
        FillBillingInfo: new URL('@assets/sidebar-images/FillBillingInfo.svg', import.meta.url).href,
        CreatePassword: new URL('@assets/sidebar-images/CreatePassword.svg', import.meta.url).href,
      };
    },
    handleDisableSubmit() {
      this.isSubmitDisabled = true;
    },
    handleEnableSubmit() {
      this.isSubmitDisabled = false;
    },
    submitStep() {
      this.stepLoading = true;
      this.currentStep.submitAction()
        .then(() => {
          this.handleEnableSubmit();
          if (this.$route.name === 'createPassword') {
            this.$router.push({
              name: 'provisioningLoading',
              query: this.$route.query,
            });
          } else if (this.getCurrentStep() !== this.$route.name) {
            this.$router.push({
              name: this.getCurrentStep(),
              query: this.$route.query,
            });
          }
        })
        .catch(error => {
          let message = this.$t('common.error_message');
          if (error?.response?.data?.message === 'Email already taken') {
            message = this.$t('step_error.email_error', { email: this.quote.organisation.contacts[0].email });
          }
          this.makeAlertToast(message);
        })
        .finally(() => {
          // In the provisioningLoading step we want to keep the loader until we receive a ping from websocket
          // or until the timeout below
          if (
            this.getCurrentStep() !== 'provisioningLoading' &&
            this.$route.name !== 'provisioningLoading'
          ) {
            this.stepLoading = false;
          }
        });
    },
    getCurrentStep() {
      if (this.isQuoteInvalid) {
        return 'error';
      }
      if (this.quote.status === 'Sent' || this.quote.status === 'Presented') {
        return 'validateQuote';
      } if (this.quote.status === 'Accepted' && !this.billingInfoCompleted) {
        return 'fillBillingInfo';
      } if (this.quote.status === 'Accepted' && this.billingInfoCompleted) {
        return 'createPassword';
      }

      return 'error';
    },
    submitProvisioning() {
      this.websocketClient = new WebsocketClient({ type: 'Onboarding', uuid: btoa(this.quote.id) });
      this.websocketClient.connect(event => {
        if (event.data === 'pingToQuoteToRedirect') {
          this.redirectUserToSkello();
        } else {
          console.error('Websocket error: unknow message', { event });
        }
        this.stepLoading = false;
      });
      setTimeout(() => {
        this.redirectUserToSkello();
      }, 60000);
      return billingAutomationClient.createOrganisation(this.quote.id, this.password);
    },
    redirectUserToSkello() {
      if (this.$route.name === 'error' || this.$route.name === 'contractChangeError') return;

      if (!this.password) {
        window.location.href = VUE_APP_SKELLO_API_URL;
      }

      skelloHttpClient.post(
        '/v3/login',
        { email: this.quote.organisation.contacts[0].email, password: this.password },
      ).then(data => {
        const token = data.data.refresh_token;
        window.location.href = `${VUE_APP_SKELLO_API_URL}/v3/api/billing_automation/users/login_with_redirection?refresh_token=${token}`;
      }).catch(() => {
        this.makeAlertToast(this.$t('common.error_message'));
        this.redirectToErrorPage(PROVISIONING_FAILED);
      });
    },
  },
};
</script>

<style lang="scss">
#toast-portal {
  position: relative;
  z-index: 101;

  + #cb-container {
    // Chargebee set their modal z-index at the highest possible value (2'147'483'647)
    // We need our error toasters over it (and in general, it's not great practice)
    // so we set it to a high, but more manageable value
    z-index: 100 !important;
  }
}
</style>
