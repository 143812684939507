<template>
  <div>
    <div
      v-if="loadingQuote"
      class="spinner"
    >
      <div class="spin-loading loading--double" />
    </div>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import { loadLanguage } from '@/plugins/i18n';
import { VUE_APP_SKELLO_API_URL } from '@/shared/config';
import { skelloHttpClient } from '@/shared/utils/clients/skelloHttpClient';
import {
  FETCH_FAILED,
  PROVISIONING_FAILED,
  INVALID_QUOTE,
} from '@/shared/constants/errors';

export default {
  name: 'App',
  data() {
    return {
      loadingQuote: true,
    };
  },
  computed: {
    ...mapState(['quote', 'password']),
    ...mapGetters(['isContractChange', 'isDevFeatureFlagActive']),

    areShopsCreated() {
      return this.quote.organisation.shops.every(shop => shop.SK_shop_id);
    },
    isOnboardingFinished() {
      return this.quote.organisation.SK_organisation_id &&
        this.quote.organisation.contacts[0].SK_user_id && this.areShopsCreated;
    },
    isQuoteInvalid() {
      return !this.quote ||
        !this.quote.organisation ||
        this.isQuoteExpired ||
        !['Sent', 'Presented', 'Accepted'].includes(this.quote.status);
    },
    isQuoteExpired() {
      const expirationDate = new Date(this.quote.valid_till);
      if (!expirationDate) return false;

      // JS counts in milliseconds, other programs in seconds
      // In order to avoid float bugs we convert up, not down
      return new Date() > expirationDate;
    },
    quoteId() {
      return this.$route.query.id;
    },
  },
  async mounted() {
    if (this.$route.name === 'error' || this.$route.name === 'contractChangeError') {
      this.loadingQuote = false;
      return;
    }

    if (!this.quoteId) {
      this.redirectToErrorPage(FETCH_FAILED, 'error');
      return;
    }

    await this.fetchFeatureFlags();

    this.fetchQuote(this.quoteId)
      .then(() => {
        if (!this.isQuoteInvalid && this.isOnboardingFinished && !this.isContractChange) {
          this.redirectUserToSkello();
        }

        if (this.isContractChange && this.quote.status !== 'Sent' && this.quote.status !== 'Presented') {
          this.redirectToErrorPage(INVALID_QUOTE, 'contractChangeError');
          return;
        }

        if (this.isContractChange) {
          try {
            this.populatePaymentInfos();
          } catch (error) {
            if (error.message === 'invalid quote') {
              console.error(error);
              this.makeAlertToast(this.$t('common.error_message'));
              this.redirectToErrorPage(INVALID_QUOTE, 'contractChangeError');
            } else {
              throw error;
            }

            return;
          }
        }

        const resolveName = this.isContractChange ? 'contractChange' : 'onboarding';

        if (this.$route.name !== resolveName) {
          this.$router.push({ name: resolveName, query: this.$route.query });
        }
      })
      .catch(error => {
        this.makeAlertToast(this.$t('common.error_message'));
        this.redirectToErrorPage(FETCH_FAILED, 'error');
        throw error;
      })
      .finally(async () => {
        // Turn the SF quote locale (EN_uk) into i18n locale (en)
        const lang =
          this.isContractChange && this.$route.query.locale ?
            this.$route.query.locale :
            this.quote.locale?.slice(0, 2)?.toLowerCase();

        await loadLanguage(lang || 'fr');
        this.loadingQuote = false;
      });
  },
  methods: {
    ...mapActions([
      'fetchQuote',
      'fetchFeatureFlags',
    ]),
    ...mapMutations(['populatePaymentInfos']),

    redirectToErrorPage(errorType, errorNameComponent) {
      if (this.loadingQuote) this.loadingQuote = false;
      this.$router.push(
        { name: errorNameComponent, query: this.$route.query, params: { error: errorType } },
      );
    },
    redirectUserToSkello() {
      if (this.$route.name === 'error' || this.$route.name === 'contractChangeError') return;

      if (!this.password) {
        window.location.href = VUE_APP_SKELLO_API_URL;
      }

      skelloHttpClient.post(
        '/v3/login',
        { email: this.quote.organisation.contacts[0].email, password: this.password },
      ).then(data => {
        const token = data.data.refresh_token;
        window.location.href = `${VUE_APP_SKELLO_API_URL}/v3/api/billing_automation/users/login_with_redirection?refresh_token=${token}`;
      }).catch(() => {
        this.makeAlertToast(this.$t('common.error_message'));
        this.redirectToErrorPage(PROVISIONING_FAILED, 'error');
      });
    },
  },
};
</script>

<style lang="scss">
// Application-wide css
* {
  box-sizing: border-box;

  // Recenters the tick in the checkbox
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  padding-top: 25vh;
  width: 100%;
}
.spin-loading {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: .25rem solid rgba(0, 186, 218, .2);
  border-top-color: #00bada;
  animation: spin 1s infinite linear;
}
.spin-loading.loading--double {
  border-style: dotted;
  border-width: 5px;
}
</style>
