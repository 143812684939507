<template>
  <div
    :ref="suggestedCompanyRegistrationReference"
    v-tooltip.bottom="!isScrollingThroughCompanyRegistrations && tooltipText"
    class="suggested-company-registration text-truncate"
  >
    <template
      v-for="labelPart in company.displayLabel"
    >
      <!--
        the next "labelPart.text" mustaches are stuck inbetween their span tags
        because Vue adds a whitespace otherwise
      -->
      <span
        v-if="labelPart.isHighlighted"
        :key="`${company.shopRegistrationNumber}-${labelPart.text}`"
        class="suggested-company-registration__result-text--highlighted"
      >{{ labelPart.text }}</span>
      <span
        v-else
        :key="`${company.shopRegistrationNumber}-${labelPart.text}s`"
      >{{ labelPart.text }}</span>
    </template>
    <span class="suggested-company-registration__result-text--italic">
      ({{ createdAtLabel }}) - {{ company.mainActivity }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SuggestedCompanyRegistration',
  props: {
    searchInput: {
      type: String,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      suggestedCompanyRegistrationReference: `company_suggestion_${this.company.shopRegistrationNumber}`,
    };
  },
  computed: {
    ...mapGetters(['isScrollingThroughCompanyRegistrations']),

    tooltipText() {
      return `${this.company.name} - ${this.company.mainActivity}`;
    },
    createdAtLabel() {
      return this.$t('fill_billing_info.billing_line.company_registration.created_at', { date: this.company.creationDate });
    },
  },
  mounted() {
    this.$nextTick(this.computeSearchResultsTooltips());
  },
  methods: {
    computeSearchResultsTooltips() {
      const element = this.$refs[this.elementReference];
      if (!element) return;

      element.tooltip = this.tooltipText;

      // triggers "reactivity", since we add the tooltip to the element,
      // vue needs to trigger the component update to effectively
      // add the tooltip to the element in the DOM
      this.company = { ...this.company };
    },
  },
};
</script>

<style lang="scss" scoped>
.suggested-company-registration {
  font-size: $fs-text-m;
  padding: 14px 14px 14px 15px;

  &:hover {
    cursor: pointer;
    background: $sk-grey-5;
  }

  &__result-text {
    &--italic {
      font-style: italic;
    }

    &--highlighted {
      color: $sk-blue;
      font-weight: $fw-semi-bold;
    }
  }
}
</style>
