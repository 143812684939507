/* eslint-disable max-len */
import { isValidIBAN } from 'ibantools';
import {
  isSIRET, isSIREN,
} from 'siret';
import { removeWhiteSpaces } from './method_helper';

export const isValidEmail = value => value && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

export const isValidIban = value => value && isValidIBAN(removeWhiteSpaces(value));

export const isValidSiret = value => value && isSIRET(value);

export const isValidSiren = value => value && isSIREN(value);
