<template>
  <div
    ref="suggested-company-registrations"
    class="suggested-company-registrations__dropdown-menu"
    :style="dropdownMenuStyle"
    @scroll="handleScroll"
    @scrollend="setIsScrollingThroughCompanyRegistrations(false)"
  >
    <SuggestedCompanyRegistration
      v-for="company in suggestedCompanies"
      :key="company.establishmentRegistrationNumber"
      ref="dropdown"
      :search-input="searchInput"
      :company="company"
      @click.native="selectSuggestedCompany(company)"
    />
  </div>
</template>

<script>
import {
  mapMutations,
  mapState,
} from 'vuex';
import SuggestedCompanyRegistration from './SuggestedCompanyRegistration';

const ORIGINAL_DROPDOWN_HEIGHT = 355;

export default {
  name: 'SuggestedCompanyRegistrations',
  components: {
    SuggestedCompanyRegistration,
  },
  props: {
    suggestedCompanies: {
      type: Array,
      required: true,
    },
    searchInput: {
      type: String,
      required: true,
    },
    relatedInput: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      suggestedCompaniesCountLabel: null,
      dropdownHeight: ORIGINAL_DROPDOWN_HEIGHT,
      isDropdownOpen: false,
      isFetchingInformations: false,
      isSireneAvailable: true,
    };
  },
  computed: {
    ...mapState(['isScrollingThroughCompanyRegistrations']),

    dropdownMenuStyle() {
      return {
        height: `${this.dropdownHeight}px`,
        'min-width': this.fixedMenuWidth,
        'max-width': this.fixedMenuWidth,
      };
    },
    fixedMenuWidth() {
      return `${this.relatedInput?.$el?.offsetWidth || 0}px`;
    },
  },
  mounted() {
    this.handleDropdownHeight(this.suggestedCompanies.length * 49 + 12); // item height and padding
  },
  updated() {
    this.handleDropdownHeight(this.suggestedCompanies.length * 49 + 12); // item height and padding
  },
  methods: {
    ...mapMutations(['setIsScrollingThroughCompanyRegistrations']),

    handleScroll() {
      if (!this.isScrollingThroughCompanyRegistrations) {
        this.setIsScrollingThroughCompanyRegistrations(true);
      }
    },
    selectSuggestedCompany(company) {
      this.$emit('select-suggested-company', company);
    },
    handleDropdownHeight(newHeight) {
      this.dropdownHeight = Math.min(ORIGINAL_DROPDOWN_HEIGHT, newHeight);
      this.$refs['suggested-company-registrations'].scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.suggested-company-registrations__dropdown-menu {
  background-color: white;
  position: absolute;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .15);
  border-radius: 2px;
  min-width: 100%;
  max-width: 719px;
  padding: 6px 0;
  overflow-y: auto;
}
</style>
