<template>
  <div>
    <div class="subtitle">
      {{ displayErrorMessage }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  FETCH_FAILED,
  PROVISIONING_FAILED,
} from '@/shared/constants/errors';

export default {
  name: 'StepError',
  props: {
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['quote']),
    displayErrorMessage() {
      if (this.error === FETCH_FAILED) {
        return this.$t('step_error.quote_error');
      }
      if (this.error === PROVISIONING_FAILED) {
        return this.$t('step_error.provisioning_error');
      }
      return this.$t('step_error.generic_message');
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 16px;
  margin-top: 13px;
  margin-bottom: 24px;
}
</style>
