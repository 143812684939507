<template>
  <div class="billing-details__wrapper">
    <SkCollapse class="billing-details__collapse">
      <template slot="labelLeft">
        <div class="billing-details__title">
          {{ $t('validate_quote.billing_details.title') }}
        </div>
      </template>
      <template slot="content">
        <div class="billing-details__content">
          <div class="billing-details__column">
            <div class="billing-details__row">
              <div class="billing-details__legend">
                {{ $t('validate_quote.billing_details.quotation_creation_date') }}
              </div>
              <div class="billing-details__data">
                {{ billingInfo.quotationCreationDate }}
              </div>
            </div>
            <div class="billing-details__row">
              <div class="billing-details__legend">
                {{ $t('validate_quote.billing_details.quotation_expiration_date') }}
              </div>
              <div class="billing-details__data">
                {{ billingInfo.quotationExpirationDate }}
              </div>
            </div>
            <div class="billing-details__row">
              <div class="billing-details__legend">
                {{ $t('validate_quote.billing_details.starting_date') }}
              </div>
              <div class="billing-details__data">
                {{ billingInfo.startingDate }}
              </div>
            </div>
          </div>
          <div class="billing-details__column">
            <div class="billing-details__row">
              <div class="billing-details__legend">
                {{ $t('validate_quote.billing_details.from') }}
              </div>
              <div class="billing-details__data">
                {{ billingLine1(billingInfo.from) }}
                <br>
                {{ billingLine2(billingInfo.from) }}
              </div>
            </div>
            <div class="billing-details__row">
              <div class="billing-details__legend">
                {{ $t('validate_quote.billing_details.to') }}
              </div>
              <div>
                {{ billingLine1(billingInfo.to) }}
                <br>
                {{ billingLine2(billingInfo.to) }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </SkCollapse>
  </div>
</template>

<script>
import { SkCollapse } from '@skelloapp/skello-ui';

export default {
  name: 'BillingDetails',
  components: { SkCollapse },
  props: {
    billingInfo: {
      type: Object,
      required: true,
    },
  },
  methods: {
    billingLine1(billingObject) {
      const lineComponent = [
        billingObject.company,
        billingObject.line1,
        billingObject.line2,
      ];

      // Remove empty elements if there are any, and list the rest
      return lineComponent.filter(Boolean).join(', ');
    },
    billingLine2(billingObject) {
      const lineComponent = [
        billingObject.zip,
        billingObject.city,
        billingObject.stateCode || billingObject.state,
        billingObject.country,
      ];
      // Remove empty elements if there are any, and list the rest
      return lineComponent.filter(Boolean).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-details__wrapper {
  padding-bottom: 24px;
}

.billing-details__collapse {
  border: 1px solid $sk-grey-10;
  border-radius: 8px;
}

.billing-details__title {
  font-weight: 700;
  font-size: 16px;
}

.billing-details__content {
  display: flex;
  padding: 16px;
  padding-top: 0;
}

.billing-details__column {
  flex: 1 1 auto;
}

.billing-details__row {
  display: flex;
}

.billing-details__legend {
  color: $sk-grey-50;
  margin-right: 8px;
  min-width: 40px;
}

.billing-details__data {
  margin-bottom: 8px;
}

</style>
