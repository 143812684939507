export const linkedShops = (organisation, customer) => {
  const billingId = customer.CB_customer_id;
  return organisation.shops.filter(
    shop => shop.CB_payment_owner_id === billingId && shop.id !== customer.id,
  );
};

export const isBillable = entity => entity.CB_customer_id === entity.CB_payment_owner_id;

// Organisation is billable (should be shown in a line)
// if there is at least one shop that is linked to it
export const organisationIsBillable =
  organisation => isBillable(organisation) && linkedShops(organisation, organisation).length > 0;

export const extractShopsNames = (quoteLineItem, quote) => {
  const shopsIds = quoteLineItem.quote_line_item_shops.map(line => line.shop_id);
  return shopsIds.map(id => quote.organisation.shops.find(shop => shop.id === id).name);
};

export const isCustomerPaymentInfoCompleted = customer => {
  const billingAddress = customer.billing_address;

  return billingAddress.line1 && billingAddress.city && billingAddress.email &&
    billingAddress.zip && customer.name && customer.registration_number &&
    customer.has_valid_payment_method;
};

export const extractPrice = (quote, price) => {
  // Salesforce locales have the format 'en_us' while JS uses the format 'en-us'
  const locale = quote.locale?.replaceAll('_', '-');

  try {
    return price.toLocaleString(
      locale,
      { style: 'currency', currency: quote.quote_currency },
    );
  } catch (err) {
    // locale given by Salesforce cannot be handled in JS, fallback to FR
    if (err instanceof RangeError) {
      return price.toLocaleString(
        'FR',
        { style: 'currency', currency: quote.quote_currency },
      );
    }
    throw err;
  }
};

const whiteSpaceRegex = /\s/g;
export const removeWhiteSpaces = text => text.replaceAll(whiteSpaceRegex, '');
