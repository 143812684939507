<template>
  <div class="provisioning-loader__wrapper">
    <div class="provisioning-loader__container">
      <SkSpinner
        size="x-large"
        class="provisioning-loader__spinner"
      />
      <div class="provisioning-loader__paragraph">
        <div class="provisioning-loader__line">
          {{ $t('provisioning_loader.subtitle.line1') }}
        </div>
        <div class="provisioning-loader__line">
          {{ $t('provisioning_loader.subtitle.line2') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SkSpinner } from '@skelloapp/skello-ui';
import {
  mapState,
  mapGetters,
} from 'vuex';
import { PROVISIONING_FAILED } from '@/shared/constants/errors';

export default {
  name: 'ProvisioningLoader',
  components: {
    SkSpinner,
  },
  computed: {
    ...mapState(['quote', 'provisioningAction']),
    ...mapGetters(['isContractChange']),
  },
  async mounted() {
    try {
      if (this.isContractChange && !!this.provisioningAction) {
        await this.provisioningAction();
      }
    } catch (error) {
      this.makeAlertToast(this.$t('common.error_message'));
      this.$router.push({ name: 'error', query: this.$route.query, params: { error: PROVISIONING_FAILED } }); // Error page
    }
  },
};
</script>

<style lang="scss" scoped>
.provisioning-loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.provisioning-loader__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
}

.provisioning-loader__paragraph {
  margin-top: 31px;
  text-align: center;
  color: $sk-blue-50;
}
</style>
