<template>
  <div class="downsell-collapse-line">
    <ToggleLine
      :line="line"
    >
      <template #top-left-text>
        {{ `${line.name} (x${line.quantity})` }}
      </template>
      <template #top-right-text>
        {{ $t('downsell_details.downselled_at') }}
      </template>
      <template #bottom-right-text>
        {{ $t('downsell_details.downselled_at') }}
      </template>
    </ToggleLine>
  </div>
</template>

<script>
import ToggleLine from '@components/ToggleLine';

export default {
  name: 'DownsellCollapseLine',
  components: {
    ToggleLine,
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
};
</script>
