<template>
  <div class="organisation-billing-line">
    <div class="organisation-billing-line__name">
      {{ organisationPaymentInfos.name }}
    </div>
    <BillingLineContent
      ref="content"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import BillingLineContent from '@components/BillingLineContent';
import {
  mapGetters,
  mapState,
} from 'vuex';

export default {
  name: 'OrganisationBillingLine',
  components: { BillingLineContent },
  computed: {
    ...mapGetters(['displayPaymentByCreditCard']),
    ...mapState(['organisationPaymentInfos']),
  },
  mounted() {
    if (!this.displayPaymentByCreditCard) {
      this.$nextTick(() => this.$refs.content.$refs.iban.focus());
    }
  },
};
</script>

<style lang="scss" scoped>
.organisation-billing-line {
  border: 1px solid $sk-grey-10;
  padding: 20px 16px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 16px;

  &__name {
    font-size: $fs-text-l;
    font-weight: $fw-semi-bold;
    line-height: 17px;
    padding-bottom: 21px;
  }
}
</style>
