import fr from './fr.yml';
import en from './en.yml';
import es from './es.yml';
import de from './de.yml';
import it from './it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
