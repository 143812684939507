<template>
  <tr
    class="collapse-line"
  >
    <td class="collapse-line__cell collapse-line__cell--wrapper">
      <ToggleLine
        :line="line"
      >
        <template #top-left-text>
          {{ line.name }}
        </template>
      </ToggleLine>
    </td>
    <td class="collapse-line__cell">
      <div>
        {{ line.quantity }}
      </div>
    </td>
    <td class="collapse-line__cell">
      {{ line.unitPrice }}
    </td>
    <td class="collapse-line__cell">
      {{ line.totalPrice }}
    </td>
  </tr>
</template>

<script>
import ToggleLine from '@components/ToggleLine';

export default {
  name: 'CollapseLine',
  components: {
    ToggleLine,
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-line__cell {
  vertical-align: top;
  line-height: 16px;
}

.collapse-line__cell--wrapper {
  text-align: left;
  display: flex;
  flex-direction: column;
}
</style>
