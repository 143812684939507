import axios from 'axios';
import { VUE_APP_SKELLO_API_URL } from '@/shared/config';

export const BaseApiConfig = {
  baseURL: VUE_APP_SKELLO_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Source-Client': 'skelloOnboarding:web',
  },
};

export const skelloHttpClient = axios.create(BaseApiConfig);
