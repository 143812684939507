import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';

import fr from './lang/module-fr.js';
import en from './lang/module-en.js';
import es from './lang/module-es.js';
import de from './lang/module-de.js';
import it from './lang/module-it.js';

Vue.use(VueI18n);

const DEFAULT_LANGUAGE = 'fr';

const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  silentTranslationWarn: true,
  messages: { fr, en, es, de, it },
});

export default i18n;

const ACCEPTED_LANGUAGES = ['fr', 'en', 'es', 'de', 'it'];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);

  return lang;
}

export function loadLanguage(lang) {
  if (!ACCEPTED_LANGUAGES.includes(lang)) {
    throw new Error(`lang ${lang} is not handled`);
  }

  return Promise.resolve(setI18nLanguage(lang));
}
